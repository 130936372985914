import { useMemo } from 'react';
import './App.css';
import Bio from './Bio';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEarthAmericas, faFile } from '@fortawesome/free-solid-svg-icons'
import Button from '@mui/material/Button';
var mobile = require('is-mobile');

function App() {

  const isMobile: boolean = useMemo(() => {
    return mobile.isMobile({ featureDetect: true, tablet: true })
  }, [])

  return (
    <div className="App">
      <div style={{ height: 60, width: "100%", borderBottom: "1px solid black", alignItems: "center", justifyContent: "center" }}>{ }
        <h3 style={{ textAlign: "center", height: 40, fontSize: 30, marginRight: 20 }}>{"ALEX HARRISON"}</h3>
      </div>
      <div style={{ padding: 40 }}>
        <FontAwesomeIcon size={"4x"} icon={faEarthAmericas} />
        <h1>{"ABOUT"}</h1>
        <p style={{ fontSize: isMobile ? "1em" : "1.5em", textAlign: "left", marginBottom: 60, color: "#3f3f3f" }}>{Bio}</p>
        <img style={{ width: "50%", marginBottom: 80, minWidth: 300 }} src='./portrait.jpg' />
        <div>
          <FontAwesomeIcon size={"4x"} icon={faFile} />
          <h1>{"RESUME"}</h1>
          <Button href='AlexHarrisonResume.pdf' style={{ backgroundColor: "#0f0f0f" }} variant="contained">VIEW RESUME</Button>
        </div>
      </div>
    </div>
  );
}

export default App;
